<template>
  <div class="material-manage ">
    <div class="material-manage-container box-shadow-light-grey">
      <div class="table-info border-radius-6 padding-20">
             <div class="search-and-operation padding-bottom-20 ">
        <div class="left-operation">
          <el-button @click="refreshData">
            刷新数据</el-button
          >
          <el-button class="custom-button-margin-left" type="primary" @click="createPage">新建投放素材</el-button>
          <el-button
          class="custom-button-margin-left"
            type="danger"
            plain
            :disabled="selectionList.length === 0"
            @click="deleteSelectedPage"
            >批量删除</el-button
          >
          <el-input
          class="custom-button-margin-left"
            v-model="search"
            clearable
            style='width:240px'
            placeholder="请输入计划名称"
            @clear="resetData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button>
          </el-input>
          <el-input
          class="custom-button-margin-left"
            v-model="selected"
            clearable
            @clear="handleClear"
            placeholder="团队/用户"
            style="width:150px;"
            @focus="chooseDeptOrUser"
          ></el-input>
          <select-dept-user
            :show="isShowDeptUser"
            @close="isShowDeptUser = false"
            @confirm="confirm"
          />
          <el-date-picker
          class="custom-button-margin-left"
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="position:relative;top:1px;"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <el-button class="custom-button-margin-left" type="primary" @click="handleSearch">查询</el-button>
          <el-button class="custom-button-margin-left" type="info" plain @click="resetFilterOption"
            >重置</el-button
          >
        </div>
      </div>
        <el-table
          :data="dataList"

          v-loading="loading"
          height="675px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            label="素材ID"
            prop="id"
            width="70"
          ></el-table-column>
          <el-table-column label="素材名称" prop="cname"></el-table-column>
          <el-table-column label="书籍名称">
            <template slot-scope="scope">
              <span>{{ scope.row.book ? scope.row.book.bookName : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="投放模式">
            <template slot-scope="scope">
              <el-tag :type="getTagType(scope.row.posType)">{{
                scope.row.posType | filterType
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创意预览" width="100">
            <template slot-scope="scope">
              <el-popover placement="right-end" width="300" trigger="hover">
                <div class="preview-detail">
                  <div class="preview-detail-container">
                    <div
                      v-if="scope.row.posType === 999 && scope.row.planGroup"
                      class="firend-circle"
                    >
                      <div class="top-info">
                        <div class="charactor-image">
                          <img
                            src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                            alt=""
                          />
                        </div>
                        <div class="text">
                          <div style="color:#576b95">
                            <span>广告主名称</span>
                            <span class="tag"
                              >广告 <i class="el-icon-arrow-down"></i
                            ></span>
                          </div>
                          <div
                            class="promoted-text"
                            ref="promotedText"
                            style="font-size:12px"
                            v-html="scope.row.planGroup.descRichTextList[0]"
                          ></div>
                        </div>
                      </div>
                      <div class="ad-image">
                        <div v-if="'preview' in scope.row">
                          <div
                            v-for="(item, index) in scope.row.preview"
                            :key="index"
                            :style="getImageStyle(scope.row.preview.length)"
                          >
                            <img :src="item" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="footer-info">
                        <p>
                          <span style="color:#576b95">了解公众号</span>
                          <i class="iconfont icon-lianjie2"></i>
                        </p>
                        <p>2 分钟前</p>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        (scope.row.posType === 101 ||
                          scope.row.posType === 0) &&
                          scope.row.planGroup
                      "
                      class="channel-circle"
                    >
                      <div class="placeholder-image">
                        <img :src="scope.row.preview[0]" alt="" />
                      </div>
                      <div class="channel-footer">
                        <div>
                          <img
                            src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                            alt=""
                          />
                          <span style="margin-left:6px">广告主名称</span>
                        </div>
                        <div>
                          <span>{{ scope.row.planGroup.buttonParam }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <img
                        src="https://h5.file.yayawx.cn/novel_put/2020121750082.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div slot="reference" class="thumbnail-img">
                  <template v-if="'preview' in scope.row">
                    <img
                      :src="
                        scope.row.preview.length
                          ? scope.row.preview[0]
                          : 'https://h5.file.yayawx.cn/novel_put/2020121750082.png'
                      "
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      src="https://h5.file.yayawx.cn/novel_put/2020121750082.png"
                      alt=""
                    />
                  </template>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            label="生成计划数量"
            prop="cNum"
            width="100"
          ></el-table-column>
          <el-table-column label="用户" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.adminUser.name }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <div slot="header">
              <span
                >计划开始时间
                <i
                  class="iconfont icon-tishi"
                  title="投放公众号计划日期必须大于或等于当前日期!"
                ></i
              ></span>
            </div>
            <template slot-scope="scope">
              <span :style="getTimeStyle(scope.row.beginTimeStr)">{{
                scope.row.beginTimeStr
              }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <div slot="header">
              <span
                >计划结束时间
                <i
                  class="iconfont icon-tishi"
                  title="投放公众号计划日期必须大于或等于当前日期!"
                ></i
              ></span>
            </div>
            <template slot-scope="scope">
              <span :style="getTimeStyle(scope.row.beginTimeStr)">{{
                scope.row.endTimeStr
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="createdTime"
          ></el-table-column>
          <el-table-column label="操作" fixed="right" width="300">
            <template slot-scope="scope">
              <el-button
              class='button-small'
                type="info"
                @click="getCrowdInfo(scope.row)"
                size="mini"
                >公众号列表</el-button
              >
              <el-button class='button-small' type="primary" size="mini" @click="editPage(scope.row)"
                >编辑</el-button
              >
              <el-button
              class='button-small'
                type="danger"
                size="mini"
                @click="deletePage(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="padding: 20px; text-align: right"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <el-drawer
        :title="`${title}`"
        size="90%"
        append-to-body
        :visible.sync="isShowDialog"
        :before-close="handleClose"
      >
        <div class="main-container" v-loading="loadingPushAppId">
          <div class="bottom-part">
            <div class="operation-list">
              <el-button type="primary" @click="isShowChannelInfo = true"
                >投放公众号</el-button
              >
              <el-button
                @click="refreshPushAppIdList"

              >
                刷新数据</el-button
              >
              <div class="status-statistics">
                <span>发送状态统计：</span>
                <span>
                  <el-tag :type="getStatusStyle(0)" slot="reference">
                    未发送：{{ getStausNum(0) }}
                  </el-tag>
                  <el-tag :type="getStatusStyle(1)" slot="reference">
                    发送中：{{ getStausNum(1) }}
                  </el-tag>
                  <el-tag :type="getStatusStyle(2)" slot="reference">
                    已发送：{{ getStausNum(2) }}
                  </el-tag>
                  <el-tag :type="getStatusStyle(3)" slot="reference">
                    发送失败：{{ getStausNum(3) }}
                  </el-tag>
                </span>
              </div>
            </div>
            <el-table
              :data="alreadyChooseChannelList"
              border
              style="width:100%;"
              height="700px"
            >
              <el-table-column label="书籍名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.bookName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="AppId"
                prop="appid"
                width="150"
              ></el-table-column>
              <el-table-column label="公众号名称" width="150">
                <template slot-scope="scope">
                  <div>
                    {{ getChannelName(scope.row) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="100">
                <template slot-scope="scope">
                  <el-tag
                    :type="getStatusStyle(scope.row.status)"
                    slot="reference"
                  >
                    {{ scope.row.status | formateStatus }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="状态消息" width="100">
                <template slot-scope="scope">
                  <div>
                    <el-popover
                      placement="top-start"
                      title=""
                      width="400"
                      trigger="click"
                      :content="handleMsg(scope.row)"
                    >
                      <el-button size="mini" plain slot="reference" class='button-small'
                        >点击查看</el-button
                      >
                    </el-popover>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="自定义人群">
                <el-table-column label="开关" width="70">
                  <template slot-scope="scope">
                    <div>
                      <el-tag
                        :type="getDmpAudienceStyle(scope.row.isDmpAudience)"
                      >
                        {{ scope.row.isDmpAudience === 0 ? "关" : "开" }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="定向人群" width="200">
                  <template slot-scope="scope">
                    <template v-if="'inDmpAudienceNames' in scope.row">
                      <div
                        v-if="
                          JSON.parse(scope.row.inDmpAudienceNames).length > 0
                        "
                        class="crowd-item"
                      >
                        <span
                          v-for="(item, index) in JSON.parse(
                            scope.row.inDmpAudienceNames
                          ).slice(0, 1)"
                          :key="index"
                          >{{ item }}</span
                        >
                        <el-popover
                          placement="right"
                          width="280"
                          trigger="hover"
                        >
                          <div class="watch-allCrowd">
                            <span
                              v-for="(item, index) in JSON.parse(
                                scope.row.inDmpAudienceNames
                              )"
                              :key="index"
                              >{{ item }}</span
                            >
                          </div>
                          <a
                            href="javascript:;"
                            slot="reference"
                            style="margin-left:5px"
                            >查看全部</a
                          >
                        </el-popover>
                      </div>
                      <div v-else>
                        <div class="empty-line">-</div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="empty-line">-</div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="排除人群" width="200">
                  <template slot-scope="scope">
                    <template v-if="'notInDmpAudienceNames' in scope.row">
                      <div
                        v-if="
                          JSON.parse(scope.row.notInDmpAudienceNames).length > 0
                        "
                        class="crowd-item"
                      >
                        <span
                          v-for="(item, index) in JSON.parse(
                            scope.row.notInDmpAudienceNames
                          ).slice(0, 1)"
                          :key="index"
                          >{{ item }}</span
                        >
                        <el-popover
                          placement="right"
                          width="280"
                          trigger="hover"
                        >
                          <div class="watch-allCrowd">
                            <span
                              v-for="(item, index) in JSON.parse(
                                scope.row.notInDmpAudienceNames
                              )"
                              :key="index"
                              >{{ item }}</span
                            >
                          </div>
                          <a
                            href="javascript:;"
                            slot="reference"
                            style="margin-left:5px"
                            >查看全部</a
                          >
                        </el-popover>
                      </div>
                      <div v-else>
                        <div class="empty-line">-</div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="empty-line">-</div>
                    </template>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column label="扩量种子人群">
                <el-table-column label="自动扩量" width="150">
                  <template slot-scope="scope">
                    <el-tag
                      :type="getExpandStyle(scope.row.expandTargetingSwitch)"
                      >{{
                        scope.row.expandTargetingSwitch ===
                        "EXPAND_TARGETING_SWITCH_CLOSE"
                          ? "关"
                          : "开"
                      }}</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column label="不可突破定向">
                  <template slot-scope="scope">
                    <div
                      v-if="
                        JSON.parse(scope.row.expandTargetingSetting).length > 0
                      "
                    >
                      <span>{{
                        JSON.parse(scope.row.expandTargetingSetting)
                          | formateSetting
                      }}</span>
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="人群">
                  <template slot-scope="scope">
                    <template v-if="'coldStartAudienceNames' in scope.row">
                      <div
                        v-if="
                          JSON.parse(scope.row.coldStartAudienceNames).length >
                            0
                        "
                        class="crowd-item"
                      >
                        <span
                          v-for="(item, index) in JSON.parse(
                            scope.row.coldStartAudienceNames
                          ).slice(0, 1)"
                          :key="index"
                        >
                          {{ item }}
                        </span>

                        <el-popover
                          placement="right"
                          width="280"
                          trigger="hover"
                        >
                          <div class="watch-allCrowd">
                            <span
                              v-for="(item, index) in JSON.parse(
                                scope.row.coldStartAudienceNames
                              )"
                              :key="index"
                              >{{ item }}</span
                            >
                          </div>
                          <a
                            href="javascript:;"
                            slot="reference"
                            style="margin-left:5px"
                            >查看全部</a
                          >
                        </el-popover>
                      </div>
                      <div v-else>
                        <div class="empty-line">-</div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="empty-line">-</div>
                    </template>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                label="创建时间"
                prop="createdTime"
                width="150"
              ></el-table-column>
            </el-table>
          </div>
        </div>
        <el-drawer
          title="投放公众号"
          size="74%"
          append-to-body
          :visible.sync="isShowChannelInfo"
          :before-close="handleChannelClose"
        >
          <div class="top-part">
            <div class="choose-channelName">
              <el-select
                v-model="channelNameList"
                filterable
                multiple
                placeholder="请选择公众号"
                size="medium"
              >
                <el-option
                  v-for="item in options"
                  :label="item.mpName"
                  :value="item.appid"
                  :key="item.appid"
                >
                </el-option>
              </el-select>
              <el-button
                @click="resetData(false)"
                type="info"
                plain
                style="margin-left:10px"
                >重置</el-button
              >
            </div>
            <div class="channel-info">
              <el-table
                :data="chooseChannelList"
                border
                style="width:100%"
                height="700px"
              >
                <el-table-column label="头像" width="70">
                  <template slot-scope="scope">
                    <img :src="scope.row.headUrl" alt="头像" />
                  </template>
                </el-table-column>
                <el-table-column
                  label="公众号名称"
                  prop="mpName"
                  width="100"
                ></el-table-column>
                <el-table-column label="自定义人群">
                  <el-table-column label="开关" width="70">
                    <template slot-scope="scope">
                      <div>
                        <span
                          style="vertical-align:middle;margin-right:5px"
                        ></span
                        ><el-switch
                          v-model="scope.row.isDmpAudience"
                        ></el-switch>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="定向人群" width="200">
                    <template slot-scope="scope">
                      <div class="choose-crowd crowd">
                        <div
                          class="choose-wrapper wrapper"
                          v-show="scope.row.isDmpAudience"
                        >
                          <div>
                            <span>定向人群</span>
                            <!-- <span @click="chooseCrowd(scope.row)"
                              >选择人群</span
                            > -->
                            <el-button
                              plain
                              size="mini"
                              class='button-small'
                              @click="chooseCrowd(scope.row)"
                              >选择人群</el-button
                            >
                          </div>
                          <div
                            class="choose-wrapper-list wrapper-list"
                            v-show="scope.row.crowdPackage.length > 0"
                          >
                            <div
                              class="choose-wrapper-item wrapper-item"
                              v-for="(item, index) in scope.row.crowdPackage"
                              :key="index"
                            >
                              <span>{{ item.name }}</span>
                              <i
                                class="iconfont icon-shanchu1"
                                style="cursor:pointer"
                                @click="delCrowd(scope.row, item, index, 1)"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div v-show="!scope.row.isDmpAudience">
                          <span style="color:#ccc">请勾自定义人群</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="排除人群">
                    <template slot-scope="scope">
                      <div
                        class="eliminate-crowd crowd"
                        v-show="scope.row.isDmpAudience"
                      >
                        <div class="eliminate-wrapper wrapper">
                          <div>
                            <span>排除人群</span>
                            <el-button
                              plain
                              size="mini"
                              class='button-small'
                              @click="handleEliminateCrowd(scope.row)"
                              >选择人群</el-button
                            >
                          </div>
                          <div
                            class="eliminate-wrapper-list wrapper-list"
                            v-show="scope.row.eliminateCrowd.length > 0"
                          >
                            <div
                              class="choose-wrapper-item wrapper-item"
                              v-for="(item, index) in scope.row.eliminateCrowd"
                              :key="index"
                            >
                              <span>{{ item.name }}</span>
                              <i
                                class="iconfont icon-shanchu1"
                                style="cursor:pointer"
                                @click="delCrowd(scope.row, item, index, 2)"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="!scope.row.isDmpAudience">
                        <span style="color:#ccc">请勾选自定义人群</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column label="种子人群">
                  <el-table-column label="开关" width="150">
                    <div slot="header">
                      <span
                        >开关
                        <i
                          class="iconfont icon-tishi"
                          title="在已选择广告定向外寻找更多合适的目标用户。仅支持智能优化出价方式。"
                        ></i
                      ></span>
                    </div>
                    <template slot-scope="scope">
                      <div class="auto-form-label">
                        <span style="vertical-align: middle;margin-right:5px;"
                          >自动扩量
                        </span>
                        <el-switch v-model="scope.row.autoForm"></el-switch>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="不可突破定向">
                    <div slot="header">
                      <span
                        >不可突破定向
                        <i
                          class="iconfont icon-tishi"
                          style="margin-right:10px;"
                          title='广告将突破已设置的各维度定向，寻找合适的用户。在此基础上，「地域、年龄、性别」等都分维度可设定为不可突破， 请根据需要合理勾选使用！如果定向人群中"性别"栏位有一项为“全部”则不可勾选“性别”'
                        ></i>
                      </span>
                    </div>
                    <template slot-scope="scope">
                      <div
                        v-if="scope.row.autoForm"
                        style="display:flex;align-items:center"
                      >
                        <el-checkbox-group v-model="scope.row.checkboxArr">
                          <el-checkbox label="area">地域</el-checkbox>
                          <el-checkbox label="age">年龄</el-checkbox>
                          <el-checkbox
                            label="gender"
                            :disabled="
                              echoNovelPlanInfo.directs.findIndex(
                                item => JSON.parse(item.gender).length === 0
                              ) > -1
                            "
                            >性别</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                      <div v-else>
                        <span style="color:#ccc">请勾选自动扩量</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="种子人群">
                    <template slot-scope="scope">
                      <div class="feed-crowd crowd" v-show="scope.row.autoForm">
                        <div class="feed-wrapper wrapper">
                          <div>
                            <span>选择种子人群</span>
                            <el-button
                              plain
                              size="mini"
                              class='button-small'
                              @click="handleFeedCrowd(scope.row)"
                              >选择人群</el-button
                            >
                          </div>
                          <div
                            class="feed-wrapper-list wrapper-list"
                            v-show="scope.row.feedCrowd.length > 0"
                          >
                            <div
                              class="choose-wrapper-item wrapper-item"
                              v-for="(item, index) in scope.row.chooseFeedCrowd"
                              :key="index"
                            >
                              <span>{{ item.name }}</span>
                              <i
                                class="iconfont icon-shanchu1"
                                style="cursor:pointer"
                                @click="delCrowd(scope.row, item, index, 3)"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="!scope.row.autoForm">
                        <span style="color:#ccc">请勾选自动扩量</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                    class='button-small'
                      type="danger"
                      style="margin-left:5px"
                      @click="delCrowdItem(scope.row)"
                      size="mini"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="cancel-or-confirm">
              <el-button
                type="primary"
                size="large"
                @click="handleOk"
                :loading="isPutButtonLoading"
                >投放</el-button
              >
            </div>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
    <choose-crowd
      v-if="isShowCrowdPackage && (type === 1 || type === 2)"
      :crowdInfo="crowdInfo"
      :isShowCrowdPackage.sync="isShowCrowdPackage"
      :mpName="mpName"
      :chooseChannelList="chooseChannelList"
      :curIndex="curIndex"
      :echoList="echoList"
      :type="type"
      @success="handleSuccess"
    ></choose-crowd>
    <choose-feed-crowd
      v-if="isShowFeedCrowd"
      :crowdInfo="feedCrowd"
      :isShowFeedCrowd.sync="isShowFeedCrowd"
      :mpName="mpName"
      :chooseChannelList="chooseChannelList"
      :curIndex="curIndex"
      :echoList="feedEchoList"
      @success="handleFeedSuccess"
    ></choose-feed-crowd>
    <home-page
      v-if="showDrawer"
      :showDrawer.sync="showDrawer"
      @closeFreshData="handleCloseFreshData"
      @close="showDrawer = false"
    ></home-page>
    <echo-home-page
      v-if="showDrawerEcho"
      :showDrawerEcho.sync="showDrawerEcho"
      @closeFreshData="handleCloseFreshData"
      @close="showDrawerEcho = false"
    ></echo-home-page>
  </div>
</template>

<script>
import {
  getWeChatPlan,
  getPerWeChatPlanDetail,
  deleteWeChatPlan,
  getPushAppIdList,
  getUserChannelList,
  getWechatRegionCrowd,
  pushAppIdCampaign
} from '@/api/novelPut'
import { formatTime } from '@/assets/js/utils'
import { mapState, mapMutations } from 'vuex'
import chooseCrowd from './../addNovelPlan/children/choose-crowd'
import chooseFeedCrowd from './../addNovelPlan/children/choose-feed-crowd'
import SelectDeptUser from '@/views/statistics/components/SelectDeptUser.vue'
import HomePage from './../components/home-page'
import EchoHomePage from './../echoNovelPlan/echo-home-page.vue'
export default {
  name: 'materialManage',
  data () {
    return {
      search: '',
      rowId: null,
      total: 0,
      title: '',
      page: 1,
      pageSize: 10,
      dataList: [],
      selectionList: [],
      loading: true,
      loadingPushAppId: false,
      isShowDialog: false,
      isShowChannelInfo: false,
      isShowCrowdPackage: false,
      isShowFeedCrowd: false,
      channelNameList: [],
      options: [],
      chooseChannelList: [], // 已选择的公众号
      crowdInfo: [],
      crowdPackage: [],
      eliminateCrowd: [],
      feedCrowd: [], // 种子人群
      curIndex: null,
      rightDefaultChecked: [], // 用于穿梭框回显的操作
      leftDefaultChecked: [],
      echoList: [], // 回显定向人群和排除人群
      feedEchoList: [], // 回显种子人群
      planId: null, // 计划id
      checkboxArr: [],
      alreadyChooseCrowd: [],
      alreadyChooseChannelList: [], // 已经发送的公众号列表
      isShowDeptUser: false,
      selected: '',
      adminUserId: null,
      deptId: null,
      beginDate: null, // 开始时间
      endDate: null,
      value1: '',
      isPutButtonLoading: false,
      rowData: null, // 当前行数据
      bookName: '', // 当前书籍名称
      showDrawer: false,
      showDrawerEcho: false
    }
  },
  filters: {
    filterType (value) {
      if (value === 0) return '公众号文章底部'
      if (value === 8) return '激励式广告'
      if (value === 101) return '优雅横版大图'
      if (value === 999) return '朋友圈信息流'
    },
    formateSetting (value) {
      const obj = {
        area: '地域',
        age: '年龄',
        gender: '性别'
      }
      const arr = []
      if (value.length > 0) {
        value.forEach(item => {
          const name = obj[`${item}`]
          arr.push(name)
        })
      }
      return arr.join(',')
    },
    formateStatus (value) {
      if (value === 0) {
        return '未发送'
      }
      if (value === 1) {
        return '发送中'
      }
      if (value === 2) {
        return '已发送'
      }
      if (value === 3) {
        return '发送失败'
      }
    }
  },
  watch: {
    value1: {
      handler (newV) {
        if (Array.isArray(newV) && newV.length > 0) {
          const start = new Date(newV[0])
          const end = new Date(newV[1])
          this.beginTime = formatTime(start)
          this.endTime = formatTime(end)
        } else {
          this.beginTime = null
          this.endTime = null
        }
      }
    },
    isShowDialog: {
      handler (newV) {
        if (newV) {
          this.getPushAppIdList()
        }
      }
    },
    isShowChannelInfo: {
      handler (newV) {
        if (newV) {
          this.getChannelList()
        }
      }
    },
    channelNameList: {
      handler (newV, oldV) {
        if (Array.isArray(newV)) {
          const list = JSON.parse(
            JSON.stringify(
              this.options.filter(channel =>
                newV.some(item => item === channel.appid)
              )
            )
          )
          // 如果是添加公众号的操作
          if (newV.length > oldV.length) {
            list.forEach(item => {
              if (
                !this.chooseChannelList.find(
                  channel => channel.appid === item.appid
                )
              ) {
                this.chooseChannelList.push(item)
              }
            })
          }
          // 如果是删除公众号的操作
          if (newV.length < oldV.length) {
            const differAppIdItem = oldV.find(item => !newV.includes(item))
            const targetIndex = this.chooseChannelList.findIndex(
              channel => channel.appid === differAppIdItem
            )
            if (targetIndex > -1) {
              this.chooseChannelList.splice(targetIndex, 1)
            }
          }
        }
      }
    },
    // !通过isDmpAudience来判断表格的哪一行变为了false
    isDmpAudience: {
      handler (newV) {
        newV.forEach((item, index) => {
          // !如果变为了false 则需要重置已选择的定向人群和排除人群
          if (!item) {
            this.chooseChannelList[index].crowdInfo = []
            this.chooseChannelList[index].crowdPackage = []
            this.chooseChannelList[index].eliminateCrowd = []
          }
        })
      }
    },
    autoForm: {
      handler (newV) {
        newV.forEach((item, index) => {
          if (!item) {
            this.chooseChannelList[index].feedCrowd = []
            this.chooseChannelList[index].chooseFeedCrowd = []
            this.chooseChannelList[index].checkboxArr = []
          }
        })
      }
    }
  },
  computed: {
    ...mapState([
      'editorList',
      'channelName',
      'isProjectSaved',
      'echoNovelPlanInfo'
    ]),
    isDmpAudience () {
      return this.chooseChannelList.map(item => item.isDmpAudience)
    },
    autoForm () {
      return this.chooseChannelList.map(item => item.autoForm)
    }
  },
  mounted () {
    this.initData()
    // ;
    // document.addEventListener('scroll',function(e){
    //   ;
    // })
  },
  methods: {
    ...mapMutations([
      'setEchoNovelPlanInfo',
      'setIsProjectSaved',
      'setResourceData'
    ]),
    initData () {
      this.loading = true
      getWeChatPlan({
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        adminUserId: this.adminUserId,
        deptId: this.deptId,
        beginTime: this.beginTime,
        endTime: this.endTime
      }).then(res => {
        this.dataList = res.list
        this.total = res.total
        this.loading = false
      })
    },
    createPage () {
      // this.$router.push({
      //   name: 'AddNovelPut'
      // })
      this.showDrawer = true
    },
    getChannelList () {
      getUserChannelList().then(res => {
        res.forEach(item => {
          item.headUrl = `${item.headUrl}/0`
          item.isDmpAudience = false
          item.crowdInfo = [] // 公众号的人群包信息
          item.crowdPackage = [] // 已选择的人群包合集
          item.eliminateCrowd = [] // 已排除的人群包信息
          item.feedCrowd = [] // 种子人群
          item.chooseFeedCrowd = [] // 已选择的种子人群
          item.autoForm = false
          item.checkboxArr = []
          // item.feedCrowd = res.filter(item=>item.type==='CUSTOMER_FILE'); // 种子人群
        })
        this.options = JSON.parse(JSON.stringify(res))
      })
    },
    getPushAppIdList () {
      this.loadingPushAppId = true
      getPushAppIdList({ id: this.rowId })
        .then(res => {
          res.forEach(item => {
            item.bookName = this.bookName
          })
          this.alreadyChooseChannelList = res
        })
        .finally(() => {
          this.loadingPushAppId = false
        })
    },
    deletePage (row) {
      this.$confirm('此操作将删除该投放计划, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const { id } = row
        deleteWeChatPlan(id).then(() => {
          this.page = 1
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success'
          })
          this.initData()
        })
      })
    },
    handleSearch () {
      this.page = 1
      this.initData()
    },
    deleteSelectedPage () {
      const ids = this.selectionList.map(item => item.id).join(',')
      this.$confirm('批量删除的投放计划将无法恢复,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteWeChatPlan(ids).then(() => {
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success'
          })
          this.page = 1
          this.initData()
        })
      })
    },
    handleSelectionChange (selection) {
      this.selectionList = selection
    },
    editPage (row) {
      const { id } = row
      getPerWeChatPlanDetail(id).then(res => {
        this.setEchoNovelPlanInfo(res)
        this.setResourceData(row)
        // this.$router.push({
        //   name: 'EchoNovelPut'
        // })
        this.showDrawerEcho = true
      })
    },
    handlePageChange (page) {
      this.page = page
      this.initData()
    },
    refreshData () {
      this.initData()
    },

    getCrowdInfo (row) {
      const {
        id,
        cname,
        book: { bookName }
      } = row
      this.rowData = row
      this.bookName = bookName
      getPerWeChatPlanDetail(id).then(res => {
        this.setEchoNovelPlanInfo(res)
        this.rowId = id
        this.title = cname
        this.isShowDialog = true
      })
    },
    handleFeedCrowd (row) {
      this.type = 3
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        const feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        this.chooseChannelList[this.curIndex].feedCrowd = this.deepClone(
          feedCrowd
        )
        this.feedCrowd = this.deepClone(feedCrowd)
        this.feedEchoList = this.deepClone(
          this.chooseChannelList[this.curIndex].chooseFeedCrowd
        )
        this.isShowFeedCrowd = true
      })
    },
    delCrowd (row, item, index, type) {
      const { appid } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      if (type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage.splice(index, 1)
        this.echoList.splice(index, 1)
      } else if (type === 2) {
        this.chooseChannelList[this.curIndex].eliminateCrowd.splice(index, 1)
        this.echoList.splice(index, 1)
      } else if (type === 3) {
        this.chooseChannelList[this.curIndex].chooseFeedCrowd.splice(index, 1)
      }
    },
    delCrowdItem (row) {
      const { appid } = row
      const targetIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.chooseChannelList.splice(targetIndex, 1)
      this.channelNameList.splice(
        this.channelNameList.findIndex(item => item === appid),
        1
      )
    },
    cancel () {
      this.isShowDialog = false
      this.resetData()
    },
    // 选择人群
    chooseCrowd (row) {
      this.type = 1
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        this.chooseChannelList[this.curIndex].crowdInfo = res
        // this.chooseChannelList[this.curIndex].feedCrowd =
        this.crowdInfo = res
        // 设置种子人群
        this.feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        // 设置回显人群
        this.echoList = this.chooseChannelList[this.curIndex].crowdPackage
        this.isShowCrowdPackage = true
      })
    },
    // 排除人群
    handleEliminateCrowd (row) {
      this.type = 2
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === row.appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        // 设置种子人群
        this.feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        const copyRes = this.deepClone(res)
        const otherCopyRes = this.deepClone(copyRes)
        this.chooseChannelList[this.curIndex].crowdInfo = copyRes

        this.echoList = this.chooseChannelList[this.curIndex].eliminateCrowd
        this.crowdInfo = otherCopyRes
        this.isShowCrowdPackage = true
      })
    },
    handleCancel () {
      this.crowdPackage = []
      this.crowdInfo = []
      this.eliminateCrowd = []
      this.feedCrowd = []
      this.isShowCrowdPackage = false
    },
    handleConfirm () {
      if (this.type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage = this.crowdPackage
      } else if (this.type === 2) {
        this.chooseChannelList[
          this.curIndex
        ].eliminateCrowd = this.eliminateCrowd
      }
      this.isShowCrowdPackage = false
      this.crowdInfo = []
      this.crowdPackage = []
      this.eliminateCrowd = []
      this.feedCrowd = []
    },
    handleSuccess (crowdList) {
      if (this.type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage = this.deepClone(
          crowdList
        )
      }
      if (this.type === 2) {
        this.chooseChannelList[this.curIndex].eliminateCrowd = this.deepClone(
          crowdList
        )
      }
    },
    handleFeedSuccess (crowdList) {
      this.chooseChannelList[this.curIndex].chooseFeedCrowd = this.deepClone(
        crowdList
      )
    },
    handleClose () {
      this.resetData()
      this.isShowDialog = false
    },
    handleClear () {
      this.adminUserId = null
      this.deptId = null
      this.initData()
    },
    handleMsg (row) {
      return 'errMsg' in row ? row.errMsg : '暂无'
    },
    getTimeStyle (timeStr) {
      const nowTime = +new Date(new Date().toLocaleDateString())
      const beginTime = +new Date(timeStr)
      if (nowTime > beginTime) {
        return {
          color: 'red !important'
        }
      } else {
        return {
          color: 'green !important'
        }
      }
    },
    getTagType (posType) {
      if (posType === 999) {
        return ''
      }
      if (posType === 101) {
        return 'success'
      }
      if (posType === 8) {
        return 'warning'
      }
      if (posType === 0) {
        return 'danger'
      }
    },
    handleOk () {
      if (this.chooseChannelList.length === 0) {
        this.$message({
          showClose: true,
          message: '请选择推送的公众号!',
          type: 'error'
        })
        return
      }
      const { beginTime } = this.echoNovelPlanInfo
      const beginTimeStamp = +new Date(beginTime * 1000)
      const nowDateStamp = +new Date(new Date().toLocaleDateString())
      if (nowDateStamp > beginTimeStamp) {
        this.$message({
          showClose: true,
          message: '投放日期有误!',
          type: 'error'
        })
        return
      }
      this.isPutButtonLoading = true

      this.setIsProjectSaved(true)
      const planAppids = this.chooseChannelList.map(item => {
        return {
          appid: item.appid,
          isDmpAudience: item.isDmpAudience ? 1 : 0,
          inDmpAudience:
            item.crowdPackage.length === 0
              ? JSON.stringify([])
              : JSON.stringify(item.crowdPackage.map(item => item.audience_id)),
          notInDmpAudience:
            item.eliminateCrowd.length === 0
              ? JSON.stringify([])
              : JSON.stringify(
                item.eliminateCrowd.map(item => item.audience_id)
              ),
          expandTargetingSwitch: item.autoForm
            ? 'EXPAND_TARGETING_SWITCH_OPEN'
            : 'EXPAND_TARGETING_SWITCH_CLOSE',
          expandTargetingSetting: JSON.stringify(item.checkboxArr),
          coldStartAudienceIdList:
            item.chooseFeedCrowd.length === 0
              ? JSON.stringify([])
              : JSON.stringify(
                item.chooseFeedCrowd.map(item => item.audience_id)
              ),
          coldStartAudienceNames:
            item.chooseFeedCrowd.length === 0
              ? JSON.stringify([])
              : JSON.stringify(item.chooseFeedCrowd.map(item => item.name)),
          inDmpAudienceNames:
            item.crowdPackage.length === 0
              ? []
              : item.crowdPackage.map(item => item.name),
          notInDmpAudienceNames:
            item.eliminateCrowd.length === 0
              ? []
              : item.eliminateCrowd.map(item => item.name)
        }
      })
      pushAppIdCampaign({
        planId: this.rowId,
        planAppids
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: '加入投放列表成功!',
            type: 'success'
          })
        })
        .finally(() => {
          this.resetData()
          this.isShowChannelInfo = false
          this.isPutButtonLoading = false
          this.refreshPushAppIdList()
        })
    },
    getStatusStyle (status) {
      if (status === 0) return 'info'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'danger'
    },
    getStausNum (status) {
      const statusNumObj = this.alreadyChooseChannelList.reduce((acc, b, c) => {
        if (!(`${b.status}` in acc)) {
          acc[`${b.status}`] = 1
        } else {
          acc[`${b.status}`]++
        }
        return acc
      }, {})
      return statusNumObj[`${status}`] ? statusNumObj[`${status}`] : 0
    },
    getExpandStyle (expandTargetingSwitch) {
      return expandTargetingSwitch === 'EXPAND_TARGETING_SWITCH_CLOSE'
        ? 'danger'
        : 'success'
    },
    getDmpAudienceStyle (isDmpAudience) {
      return isDmpAudience === 0 ? 'danger' : 'success'
    },
    handleChannelClose () {
      this.isShowChannelInfo = false
      this.resetData()
    },
    chooseDeptOrUser () {
      this.isShowDeptUser = true
    },
    confirm (selected) {
      const { id, type } = selected
      if (type === 1) {
        this.adminUserId = id
        this.deptId = null
      }
      if (type === 0) {
        this.adminUserId = null
        this.deptId = id
      }
      this.selected = selected.name
    },
    getChannelName (row) {
      return 'wechatAdChannel' in row ? row.wechatAdChannel.mpName : '-'
    },
    async refreshPushAppIdList () {
      await this.getPushAppIdList()
      this.$message({
        showClose: true,
        message: '刷新成功!',
        type: 'success'
      })
    },
    handleCloseFreshData () {
      this.initData()
    },
    getImageStyle (imageLen) {
      switch (imageLen) {
        case 1:
          return {
            width: '75%',
            height: '65%'
          }
        case 3:
          return {
            width: '22%',
            height: '22%'
          }
        case 4:
          return {
            width: '30%',
            height: '30%'
          }
        case 6:
          return {
            width: '28%',
            height: '28%'
          }
      }
    },
    resetFilterOption () {
      this.selected = ''
      this.adminUserId = ''
      this.deptId = ''
      this.beginTime = null
      this.endTime = null
      this.value1 = null
      this.search = ''
      this.page = 1
      this.initData()
    },
    deepClone (val) {
      return JSON.parse(JSON.stringify(val))
    },
    getParams () {
      const {
        cname,
        bookId,
        bid,
        dayBudget,
        topImgStr,
        // topImgs,
        planTopImgs,
        cNum,
        cAdNum,
        posType,
        beginTime,
        endTime,
        timeset,
        bidActionType,
        bidStrategy,
        bidObjective,
        education,
        os,
        devicePrice,
        deviceBrandModel,
        telcom,
        connection,
        inActionList,
        notInActionList,
        pages,
        planGroup,
        directs,
        timesetType,
        selectedList
      } = this.echoNovelPlanInfo
      const pageIds = pages.map(item => item.id)
      const topImgs = planTopImgs.map(item => item.topImg)
      const obj = {
        cname,
        bookId,
        bid,
        dayBudget,
        topImgStr,
        topImgs,
        cNum,
        cAdNum,
        posType,
        beginTime,
        endTime,
        timeset,
        bidActionType,
        bidStrategy,
        bidObjective,
        education,
        os,
        devicePrice,
        deviceBrandModel,
        telcom,
        connection,
        inActionList,
        notInActionList,
        pageIds,
        planGroup,
        directs,
        timesetType,
        selectedList
      }

      return obj
    },
    resetData (flag = true) {
      // this.search = "";
      // this.page = 1;
      this.channelNameList = []
      if (flag) {
        this.options = []
      }
      this.chooseChannelList = []
      this.feedCrowd = []
      this.crowdInfo = []
      this.crowdPackage = []
      this.eliminateCrowd = []
      this.curIndex = null
      this.rightDefaultChecked = []
      this.leftDefaultChecked = []
    }
  },
  components: {
    chooseCrowd,
    chooseFeedCrowd,
    SelectDeptUser,
    HomePage,
    EchoHomePage
  }
}
</script>
<style lang="scss" scoped>
a {
  color: #459ae9;
}
/deep/ .eleImg{
 width: 16px;
  height: 14px;
  vertical-align: bottom;
}
/deep/ .el-drawer {
  padding: 0 20px 20px;
  /deep/ .el-drawer__header {
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    padding-left: 0;
  }
  /deep/ .el-drawer__body {
    position: relative;
  }
}

/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}
/deep/ .el-select {
  width: 500px;
  .el-select__tags {
    max-width: 700px;
  }
}
/deep/ .el-checkbox {
  margin-right: 5px;
}
/deep/ .el-checkbox__label {
  padding-left: 2px;
}

// /deep/ .el-table__row:hover {
//   td {
//     background-color: #ffffff !important;
//   }
// }
// /deep/ .el-table__row-- d:hover {
//   td {
//     background-color: #fafafa !important;
//   }
// }
.el-date-editor {
  padding-top: 2px;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.watch-allCrowd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 150px;
  overflow: auto;
  margin-left: 5px;
  span {
    margin-bottom: 3px;
  }
}
.thumbnail-img {
  img {
    width: 50px;
    height: 30px;
  }
}

.top-part {
  position: relative;
  //  overflow-y: hidden;
  height: 476px;
  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .choose-channelName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .channel-info {
    margin-top: 20px;
    .crowd {
      > div {
        padding: 4px;

        // border: 1px solid #f2f2f2;
        border-radius: 4px;
        > div:nth-of-type(1) {
          span {
            padding: 4px;
            border-radius: 4px;
          }
          span:nth-of-type(2) {
            &:hover {
              cursor: pointer;
              background-color: #e0e0e0;
            }
          }
        }
      }
    }
    .wrapper {
      > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .wrapper-list {
        .wrapper-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            color: #d6d6d6;
          }
          &:hover {
            background: #f5f5f5;
            i {
              color: #000;
            }
          }
        }
      }
    }
  }
  .cancel-or-confirm {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}

.material-manage {
  .material-manage-container {
    box-sizing: border-box;
    .search-and-operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      // padding: 0 15px;

      box-sizing: inherit;
    }
    .table-info {
      background: #fff;
      // padding: 15px;
      box-sizing: inherit;
      // height: 760px;
    }
  }
}
.main-container {
  .bottom-part {
    margin-top: 10px;
  }
  .operation-list {
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    .status-statistics {
      margin-left: 20px;
      > span {
        > span {
          margin-right: 5px !important;
        }
      }
    }
  }
}
.tag {
  border: 1px solid #a0a0a0;
  border-radius: 2px;
  color: #a0a0a0;
  font-size: 12px;
  padding: 2px 4px;
  transform: scale(0.8);
}
.preview-detail {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  .preview-detail-container {
    .firend-circle {
      .top-info {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        .charactor-image {
          margin-right: 10px;
          img {
            width: 30px;
            height: 30px;
            border-radius: 4px;
          }
        }
        .text {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          position: relative;
          top: -4px;
          > div:nth-of-type(1) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // align-items: center;
          }
          .promoted-text {
            // padding-right:10px;
            line-height: 14px;
          }
        }
      }
      .ad-image {
        > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin: 14px 37px 0;
          > div {
            margin: 4px;

            img {
              width: 100%;
            }
          }
        }
      }
      .footer-info {
        margin-top: 6px;
        margin-left: 37px;
        font-size: 12px;
      }
    }
    .channel-circle {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      .placeholder-image {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .channel-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div:nth-of-type(1) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
          }
        }
        > div:nth-of-type(2) {
          flex-grow: 1;
          text-align: right;
          > span {
            background: #f2f2f2;
            height: 32px;
            line-height: 32px;
            color: #06ae56;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
</style>
