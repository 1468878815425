<template>
  <el-drawer
    append-to-body
    size="90%"
    :visible.sync="showDrawer"
    :before-close="handleClose"
    id='home-page'
  >
    <div class="home-page " v-if="showHomePage">
      <div class="home-page-header part ">
        <div class="put-target-title">
          <p class="part-title">推广目标</p>
          <p class="part-desc">选择广告推广的目标，实现你的营销目标</p>
        </div>
        <div class="put-target-list">
          <div
            v-for="target in targetList"
            :key="target.id"
            class="put-target-item"
            :class="{ 'selected-active': putTarget === 1 }"
          >
            <div>
              <div class="svg">
                <template v-if="putTarget === 1">
                  <svg viewBox="0 0 48 48">
                    <path
                      data-role="primary"
                      d="M27.25 19.25c0 1.7949-1.4551 3.25-3.25 3.25s-3.25-1.4551-3.25-3.25S22.2051 16 24 16s3.25 1.4551 3.25 3.25zM17.5 29.8c0-2.651 2.149-4.8 4.8-4.8h3.4c2.651 0 4.8 2.149 4.8 4.8 0 .6627-.5373 1.2-1.2 1.2H18.7c-.6627 0-1.2-.5373-1.2-1.2z"
                      fill="#00bf8a"
                    ></path>
                    <path
                      data-role="primary"
                      fill-rule="evenodd"
                      d="M40 24c0 8.8366-7.1634 16-16 16S8 32.8366 8 24 15.1634 8 24 8s16 7.1634 16 16zm-2 0c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14z"
                      clip-rule="evenodd"
                      fill="#00bf8a"
                    ></path>
                  </svg>
                </template>
              </div>
            </div>
            <div style="font-weight:bold">
              {{ target.name }}
            </div>
            <i class="iconfont icon-xuanze2-copy xuanze"> </i>
          </div>
        </div>
      </div>
      <div class="novel-put-placement part">
        <div>
          <p class="part-title">投放位置</p>
          <p class="part-desc">选择广告的展现位置</p>
        </div>
        <div class="novel-put-placement-container">
          <div
            class="type friendily-circle"
            :style="typeStyle"
            :class="{ 'selected-active': posType === 999 }"
            @click="chooseType(999)"
          >
            <div class="type-title">
              <template v-if="posType !== 999">
                <svg viewBox="0 0 48 48" style="margin-right: 8px; width: 48px">
                  <path
                    data-role="tertiary"
                    d="M15.6299 27.2904l4.5089-4.4958v8.7804c-1.6284-.7473-2.7548-1.8154-3.4786-2.7067-.3721-.4583-.6374-.8695-.8095-1.1655a6.5295 6.5295 0 01-.1897-.349 3.9424 3.9424 0 01-.0311-.0634z"
                    fill="#c7c7c7"
                  ></path>
                  <path
                    data-role="secondary"
                    d="M15.0674 20.1049l6.3487.0075-6.231 6.2089c-.6213-1.6762-.6525-3.2272-.5243-4.3695.066-.5873.1741-1.0661.2655-1.3975a6.4187 6.4187 0 01.117-.3813 4.2478 4.2478 0 01.0241-.0681zM21.1057 31.9157l.0076-6.3006 6.2269 6.209c-1.6808.6185-3.236.6433-4.3815.5092-.589-.0689-1.0692-.1798-1.4015-.2733a6.4828 6.4828 0 01-.3826-.1196 6.1976 6.1976 0 01-.0689-.0247zM28.1861 31.3561l-4.4159-4.4331h8.8127c-.7507 1.6213-1.8455 2.7283-2.7633 3.4344-.472.363-.8968.6197-1.2031.7854a6.5363 6.5363 0 01-.3612.182 3.5409 3.5409 0 01-.0692.0313z"
                  ></path>
                  <path
                    data-role="primary"
                    d="M32.8885 25.9582l-6.2861-.0075 6.2309-6.2089c.6203 1.6757.6361 3.2261.4925 4.3682-.0738.5872-.1898 1.0659-.2871 1.3972a6.3112 6.3112 0 01-.1242.3813 3.2117 3.2117 0 01-.026.0697zM24.2458 19.1402l-4.5392-4.4953-.0282.0137-.0347.0173a6.521 6.521 0 00-.347.1891c-.2944.1716-.7034.4362-1.1597.8072-.8874.7215-1.9521 1.8445-2.7017 3.468h8.8105zM26.9765 14.0837l-.0076 6.3005-6.227-6.2089c1.6808-.6185 3.236-.6434 4.3815-.5093.5891.069 1.0693.1799 1.4016.2734.1662.0467.2953.089.3825.1196.0271.0094.0501.0178.069.0247zM27.9436 23.2709l4.4466-4.3729a4.642 4.642 0 00-.0314-.0696 6.5104 6.5104 0 00-.1826-.3632c-.1661-.3079-.4235-.7348-.7877-1.2088-.7082-.9217-1.8185-2.0197-3.4449-2.7681v8.7826z"
                    fill="#c7c7c7"
                  ></path>
                  <path
                    data-role="primary"
                    fill-rule="evenodd"
                    d="M13 4c-2.2091 0-4 1.7909-4 4v32c0 2.2091 1.7909 4 4 4h22c2.2091 0 4-1.7909 4-4V8c0-2.2091-1.7909-4-4-4H13zm22 2H13c-1.1046 0-2 .8954-2 2v32c0 1.1046.8954 2 2 2h22c1.1046 0 2-.8954 2-2V8c0-1.1046-.8954-2-2-2z"
                    clip-rule="evenodd"
                    fill="#c7c7c7"
                  ></path>
                </svg>
              </template>
              <template v-else>
                <svg viewBox="0 0 48 48" style="margin-right: 8px; width: 48px">
                  <path
                    data-role="tertiary"
                    d="M15.6299 27.2904l4.5089-4.4958v8.7804c-1.6284-.7473-2.7548-1.8154-3.4786-2.7067-.3721-.4583-.6374-.8695-.8095-1.1655a6.5295 6.5295 0 01-.1897-.349 3.9424 3.9424 0 01-.0311-.0634z"
                    fill="#00bf8a"
                  ></path>
                  <path
                    data-role="secondary"
                    d="M15.0674 20.1049l6.3487.0075-6.231 6.2089c-.6213-1.6762-.6525-3.2272-.5243-4.3695.066-.5873.1741-1.0661.2655-1.3975a6.4187 6.4187 0 01.117-.3813 4.2478 4.2478 0 01.0241-.0681zM21.1057 31.9157l.0076-6.3006 6.2269 6.209c-1.6808.6185-3.236.6433-4.3815.5092-.589-.0689-1.0692-.1798-1.4015-.2733a6.4828 6.4828 0 01-.3826-.1196 6.1976 6.1976 0 01-.0689-.0247zM28.1861 31.3561l-4.4159-4.4331h8.8127c-.7507 1.6213-1.8455 2.7283-2.7633 3.4344-.472.363-.8968.6197-1.2031.7854a6.5363 6.5363 0 01-.3612.182 3.5409 3.5409 0 01-.0692.0313z"
                    fill="#00bf8a"
                  ></path>
                  <path
                    data-role="primary"
                    d="M32.8885 25.9582l-6.2861-.0075 6.2309-6.2089c.6203 1.6757.6361 3.2261.4925 4.3682-.0738.5872-.1898 1.0659-.2871 1.3972a6.3112 6.3112 0 01-.1242.3813 3.2117 3.2117 0 01-.026.0697zM24.2458 19.1402l-4.5392-4.4953-.0282.0137-.0347.0173a6.521 6.521 0 00-.347.1891c-.2944.1716-.7034.4362-1.1597.8072-.8874.7215-1.9521 1.8445-2.7017 3.468h8.8105zM26.9765 14.0837l-.0076 6.3005-6.227-6.2089c1.6808-.6185 3.236-.6434 4.3815-.5093.5891.069 1.0693.1799 1.4016.2734.1662.0467.2953.089.3825.1196.0271.0094.0501.0178.069.0247zM27.9436 23.2709l4.4466-4.3729a4.642 4.642 0 00-.0314-.0696 6.5104 6.5104 0 00-.1826-.3632c-.1661-.3079-.4235-.7348-.7877-1.2088-.7082-.9217-1.8185-2.0197-3.4449-2.7681v8.7826z"
                    fill="#00bf8a"
                  ></path>
                  <path
                    data-role="primary"
                    fill-rule="evenodd"
                    d="M13 4c-2.2091 0-4 1.7909-4 4v32c0 2.2091 1.7909 4 4 4h22c2.2091 0 4-1.7909 4-4V8c0-2.2091-1.7909-4-4-4H13zm22 2H13c-1.1046 0-2 .8954-2 2v32c0 1.1046.8954 2 2 2h22c1.1046 0 2-.8954 2-2V8c0-1.1046-.8954-2-2-2z"
                    clip-rule="evenodd"
                    fill="#00bf8a"
                  ></path>
                </svg>
              </template>
            </div>
            <div class="type-info" :class="{ 'active-text': posType === 999 }">
              <p style="font-size:13px;">
                <span style="font-weight:bold">朋友圈信息流</span>
              </p>
              <p style="font-size:12px;margin-top:9px">
                向浏览微信朋友圈的用户，精准展现广告
              </p>
            </div>
            <i
              class="iconfont icon-xuanze2-copy"
              :class="{ 'hover-class': posType === 999 }"
            ></i>
          </div>
          <div
            class="type horizontal-version-picture"
            :style="typeStyle"
            :class="{ 'selected-active': posType === 101 }"
            @click="chooseType(101)"
          >
            <div class="type-title">
              <template
                v-if="posType !== 101 && posType !== 8 && posType !== 0"
              >
                <div style="width:48px;height:48px;margin-right: 7px;">
                  <svg viewBox="0 0 48 48">
                    <path
                      data-role="secondary"
                      d="M15.5 12a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-17zM15.5 16a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-12z"
                      fill="#B2B2B2"
                    ></path>
                    <path
                      data-role="primary"
                      d="M15 21c-.5523 0-1 .4477-1 1v11c0 .5523.4477 1 1 1h18c.5523 0 1-.4477 1-1V22c0-.5523-.4477-1-1-1H15z"
                      fill="#B2B2B2"
                    ></path>
                    <path
                      data-role="primary"
                      fill-rule="evenodd"
                      d="M9 8c0-2.2091 1.7909-4 4-4h22c2.2091 0 4 1.7909 4 4v32c0 2.2091-1.7909 4-4 4H13c-2.2091 0-4-1.7909-4-4V8zm4-2h22c1.1046 0 2 .8954 2 2v32c0 1.1046-.8954 2-2 2H13c-1.1046 0-2-.8954-2-2V8c0-1.1046.8954-2 2-2z"
                      clip-rule="evenodd"
                      fill="#B2B2B2"
                    ></path>
                  </svg>
                </div>
              </template>
              <template
                v-if="posType === 101 || posType === 8 || posType === 0"
              >
                <div style="width:48px;height:48px;margin-right: 7px;">
                  <svg viewBox="0 0 48 48">
                    <path
                      data-role="secondary"
                      d="M15.5 12a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-17zM15.5 16a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-12z"
                      fill="#00bf8a"
                    ></path>
                    <path
                      data-role="primary"
                      d="M15 21c-.5523 0-1 .4477-1 1v11c0 .5523.4477 1 1 1h18c.5523 0 1-.4477 1-1V22c0-.5523-.4477-1-1-1H15z"
                      fill="#00bf8a"
                    ></path>
                    <path
                      data-role="primary"
                      fill-rule="evenodd"
                      d="M9 8c0-2.2091 1.7909-4 4-4h22c2.2091 0 4 1.7909 4 4v32c0 2.2091-1.7909 4-4 4H13c-2.2091 0-4-1.7909-4-4V8zm4-2h22c1.1046 0 2 .8954 2 2v32c0 1.1046-.8954 2-2 2H13c-1.1046 0-2-.8954-2-2V8c0-1.1046.8954-2 2-2z"
                      clip-rule="evenodd"
                      fill="#00bf8a"
                    ></path>
                  </svg>
                </div>
              </template>
            </div>
            <div
              class="type-info"
              :class="{
                'active-text': posType === 101 || posType === 8 || posType === 0
              }"
            >
              <p style="font-size:13px;">
                <span style="font-weight:bold">公众平台流量</span>
              </p>
              <p style="font-size:12px;margin-top:9px">
                向公众号、小程序、小游戏等微信公众平台流量场景下的用户展示广告
              </p>
            </div>
            <i
              class="iconfont icon-xuanze2-copy"
              :class="{
                'hover-class': posType === 101 || posType === 8 || posType === 0
              }"
            ></i>
          </div>
        </div>
        <template v-if="posType === 101 || posType === 8 || posType === 0">
          <div class="put-direction">
            <div>
              <span
                @click="methodType = 1"
                :class="{ 'is-selected': methodType === 1 }"
                >按形态投放</span
              >
              <span
                @click="methodType = 2"
                :class="{ 'is-selected': methodType === 2 }"
                >按广告位投放</span
              >
            </div>
            <div class="put-direction-tips tips" v-if="methodType === 2">
              将广告投放到单一广告位
            </div>
            <div class="put-direction-tips tips" v-else>
              将广告投放到相同形态的多个广告位
            </div>
          </div>
          <div class="choose-methods" v-if="methodType === 2">
            <div class="methods-list">
              <div
                class="methods-item"
                v-for="item in methodsList"
                :key="item.id"
                :class="{ 'selected-active': posType === item.posType }"
                @click="handleItemClick(item)"
              >
                <div class="methods-item-desc">
                  <p style="font-size:13px;font-weight:bold">{{ item.name }}</p>
                  <p style="font-size:12px">{{ item.desc }}</p>
                </div>
              </div>
            </div>
            <div class="thumbnail-image">
              <div class="preview-image">
                <img
                  v-if="posType === 0"
                  src="./../../../assets/method-preview01.png"
                  alt=""
                />
                <img
                  v-else-if="posType === 8"
                  src="./../../../assets/method-preview02.png"
                  alt=""
                />
                <img
                  v-else-if="posType === 101"
                  src="./../../../assets/method-preview03.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="choose-method-shape" v-if="methodType === 1">
            <div class="methods-list">
              <div
                class="methods-item"
                v-for="item in methodsShapeList"
                :key="item.id"
                :class="{ 'selected-active': posType === item.posType }"
                @click="handleItemClick(item)"
              >
                <div class="methods-item-desc">
                  <div class="svg">
                    <template v-if="posType !== 101">
                      <svg viewBox="0 0 48 48">
                        <path
                          data-role="secondary"
                          d="M15.5 12a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-17zM15.5 16a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-12z"
                          fill="#c7c7c7"
                        ></path>
                        <path
                          data-role="primary"
                          d="M15 21c-.5523 0-1 .4477-1 1v11c0 .5523.4477 1 1 1h18c.5523 0 1-.4477 1-1V22c0-.5523-.4477-1-1-1H15z"
                          fill="#c7c7c7"
                        ></path>
                        <path
                          data-role="primary"
                          fill-rule="evenodd"
                          d="M9 8c0-2.2091 1.7909-4 4-4h22c2.2091 0 4 1.7909 4 4v32c0 2.2091-1.7909 4-4 4H13c-2.2091 0-4-1.7909-4-4V8zm4-2h22c1.1046 0 2 .8954 2 2v32c0 1.1046-.8954 2-2 2H13c-1.1046 0-2-.8954-2-2V8c0-1.1046.8954-2 2-2z"
                          clip-rule="evenodd"
                          fill="#c7c7c7"
                        ></path>
                      </svg>
                    </template>
                    <template v-else>
                      <svg viewBox="0 0 48 48" >
                        <path
                          data-role="secondary"
                          d="M15.5 12a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-17zM15.5 16a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-12z"
                          fill="#00bf8a"
                        ></path>
                        <path
                          data-role="primary"
                          d="M15 21c-.5523 0-1 .4477-1 1v11c0 .5523.4477 1 1 1h18c.5523 0 1-.4477 1-1V22c0-.5523-.4477-1-1-1H15z"
                          fill="#00bf8a"
                        ></path>
                        <path
                          data-role="primary"
                          fill-rule="evenodd"
                          d="M9 8c0-2.2091 1.7909-4 4-4h22c2.2091 0 4 1.7909 4 4v32c0 2.2091-1.7909 4-4 4H13c-2.2091 0-4-1.7909-4-4V8zm4-2h22c1.1046 0 2 .8954 2 2v32c0 1.1046-.8954 2-2 2H13c-1.1046 0-2-.8954-2-2V8c0-1.1046.8954-2 2-2z"
                          clip-rule="evenodd"
                          fill="#00bf8a"
                        ></path>
                      </svg>
                    </template>
                  </div>
                  <div>
                    <p style="font-size:13px;font-weight:bold">
                      {{ item.name }}
                    </p>
                    <p style="font-size:12px">{{ item.desc }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="thumbnail-image">
              <div class="preview-image">
                <img
                  v-if="posType === 0"
                  src="./../../../assets/method-preview01.png"
                  alt=""
                />
                <img
                  v-else-if="posType === 8"
                  src="./../../../assets/method-preview02.png"
                  alt=""
                />
                <img
                  v-else-if="posType === 101"
                  src="./../../../assets/method-preview03.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="novel-put-methods part">
        <div>
          <p class="part-title">投放方式</p>
          <p class="part-desc">根据你的实际情况，选择广告购买的方式。</p>
        </div>
        <div class='type selected-active'>
          <div class="svg">
            <svg viewBox="0 0 48 48" style='width:48px'>
              <path
                data-role="secondary"
                d="M27 13H12c-.5523 0-1 .4477-1 1s.4477 1 1 1h15c.5523 0 1-.4477 1-1s-.4477-1-1-1zM34 20v2.1796l-2 1.1547V20H13v10h8.3601a3.7218 3.7218 0 00-.3385 2H13c-1.1046 0-2-.8954-2-2V20c0-1.1046.8954-2 2-2h19c1.1046 0 2 .8954 2 2zM31 13h2c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1s.4477-1 1-1z"
                fill="#00bf8a"
              ></path>
              <path
                data-role="primary"
                d="M36 13v6.4934a3.6885 3.6885 0 012-.4904V13c0-2.2091-1.7909-4-4-4H11c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h12.548l-1.1547-2H11c-1.1046 0-2-.8954-2-2V13c0-1.1046.8954-2 2-2h23c1.1046 0 2 .8954 2 2z"
                fill="#00bf8a"
              ></path>
              <path
                data-role="primary"
                d="M42.924 28.0862l-3.5984-6.2324c-.4713-.8165-1.5153-1.0962-2.3317-.6248l-.0352.0203c-.703.4059-1.0081 1.2364-.7783 1.9805l-10.3882 5.9974c-.532-.4145-1.2832-.4897-1.9032-.1318l-.0352.0204c-.8165.4713-1.0962 1.5153-.6248 2.3317l3.5983 6.2325c.4713.8164 1.5153 1.0961 2.3317.6247l.0352-.0203c.7711-.4451 1.0634-1.4011.6965-2.1937l-.0167-.0307 4.48-2.586 3.2448 5.6195c.463.8018 1.4883 1.0766 2.2901.6136.8019-.4629 1.0766-1.4882.6137-2.2901l-3.2446-5.62 2.9026-1.674c.5296.5703 1.4008.7209 2.1034.3153l.0352-.0203c.8164-.4714 1.0961-1.5154.6248-2.3318z"
                fill="#00bf8a"
              ></path>
            </svg>
          </div>
          <div class='text active-text'>
             <p style='font-weight:bold;font-size:13px'>竞价购买广告</p>
             <p style='font-size:12px;margin-top:5px;line-height:1.3'>通过调整广告曝光单价和设计优质广告创意，与其他客户竞争广告展现机会，根据广告表现最大程度提升广告效果。</p>
          </div>
          <i class='iconfont icon-xuanze2-copy hover-class'></i>
        </div>
      </div>
      <div class="next-step">
        <el-button type="primary" size='large' @click="handleNextStep">下一步</el-button>
      </div>
    </div>
    <add-novel-put
      v-else
      :posType="posType"
      :showDrawer.sync="showDrawer"
      @goBack="handleGoBack"
      @closeDrawer="handleCloesDrawer"
    ></add-novel-put>
  </el-drawer>
</template>
<script>
import addNovelPut from './../addNovelPlan/index'
import { mapMutations } from 'vuex'
export default {
  name: 'homePage',
  props: ['showDrawer'],
  data () {
    return {
      putTarget: 1,
      showHomePage: true,
      methodType: 1, // 1 代表形态投放 2代表广告位投放
      targetList: [
        {
          name: '推广我的公众号',
          id: 1
        }
      ],
      typeStyle: {
        cursor: 'pointer'
      },
      posType: 999,
      methodsList: [
        {
          id: 1,
          name: '公众号文章底部',
          desc:
            '在文章底部展现广告，为获取更多曝光，竞价广告可能投放至小程序和支付后场景',
          posType: 0
        },
        {
          id: 2,
          name: '激励式广告',
          desc: '在小程序流程中鼓励用户主动收看你的广告，小程序包含小游戏类目',
          posType: 8
        }
      ],
      methodsShapeList: [
        {
          id: 1,
          name: '优雅横版大图',
          desc: '广告位：公众号文章底部、中部，小程序插屏',
          posType: 101
        }
      ]
    }
  },
  watch: {
    showDrawer: {
      handler (newV) {
        if (newV) {
          this.resetFirstStepData()
          this.resetSecondStepData()
          this.resetThirdStepData()
        }
      },
      immediate: true
    },
    methodType: {
      handler (newV) {
        if (newV === 2) {
          this.posType = 0
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setFirstStepData',
      'resetFirstStepData',
      'resetSecondStepData',
      'resetThirdStepData'
    ]),
    chooseType (type) {
      this.posType = type
    },
    handleClose () {
      this.$confirm('此操作将会丢失目前创建的内容,是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('close')
      }).catch(() => {})
    },
    handleNextStep () {
      this.$nextTick(() => {
        this.setFirstStepData({ posType: this.posType })
        this.showHomePage = false
      })
    },
    handleItemClick (val) {
      const { posType } = val
      this.posType = posType
    },
    handleGoBack () {
      this.showHomePage = true
    },
    handleCloesDrawer () {
      this.$emit('update:showDrawer', false)
      this.$emit('closeFreshData')
    }
  },
  components: {
    addNovelPut
  }
}
</script>
<style lang="scss" scoped>

.tips {
  color: #a3a3a3;
  font-size: 12px;
}
/deep/ .el-drawer {
  background: #f2f5f3;
  padding: 16px !important;
  /deep/ #el-drawer__title {
    height: 0px;
    margin: 0;
    padding: 0;
  }
  /deep/ .el-drawer__title{
    padding:0
  }
}
.svg {
  width: 48px;
  margin: 0 auto;
}
.selected-active {
  border: 1px solid #00bf8a !important;
  background: #fafefc;
  color:#00bf8a;
}
.active-text {
  color:#00bf8a;
}

.part {
  // margin-top: 10px;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 24px;
  margin-bottom:16px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px #e0e6ed;
}
.part-title {
  font-weight: bold;
  font-size: 13px;
}
.part-desc {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}
.next-step {
  text-align: center;
  margin-top: 20px;
}
#home-page {
  // background: #fff;
  // z-index:9999 !important;
  .home-page-header {
    .put-target-list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(8, 120px);
      column-gap: 16px;

      font-size: 13px;
      .put-target-item {
        border-radius: 4px;
        border: 1px solid #ebebeb;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        padding: 8px 16px 16px;
        position: relative;
        cursor: pointer;
        > div:nth-of-type(1) {
          width: 100%;
          text-align: center;
        }
        > div:nth-last-of-type(1) {
          font-size: 12px;
        }
        .xuanze {
          position: absolute;
          top: 8%;
          left: 84%;
        }
      }
    }
  }
  .novel-put-placement {
    .novel-put-placement-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .put-direction {
      margin-top: 30px;
      > div:nth-of-type(1) {
        border-radius: 4px;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03), 0 0 0 1px #e3e3e3;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 183px;
        font-size: 13px;
        cursor: pointer;
        > span {
          padding: 6px 10px;
        }
        .is-selected {
          background: #f3f3f3;
        }
      }
      .put-direction-tips {
        margin-top: 15px;
      }
    }
    .choose-method-shape {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .methods-list {
        width: 700px;
        margin-right: 100px;
        .methods-item {
          width: 330px;
          border: 1px solid #ebebeb;
          margin-right: 15px;
          padding: 12px 16px;
          border-radius: 4px;
          .methods-item-desc {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .svg {
              margin: 0;
            }
            > div:nth-of-type(2) {
              > p {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    .choose-methods {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
      .methods-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 100px;
        .methods-item {
          border: 1px solid #ebebeb;
          border-radius: 4px;
          padding: 12px 16px;
          margin-right: 15px;
          cursor: pointer;
          .methods-item-desc {
            width: 300px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            p {
              margin: 4px 0;
              line-height: 1.5;
            }
            > p:nth-of-type(1) {
              width: 150px;
            }
          }
        }
      }

      .thumbnail-image {
      }
    }
  }
  .novel-put-methods {
    > div:nth-of-type(2) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 12px;
      width:500px;
      .text{
        margin-left:10px;
      }
    }
  }
}
.type {
  position: relative;
  margin: 20px 10px 10px 0;
  padding: 10px;
  width: 450px;
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: #1f1f1f;
  i {
    position: absolute;
    top: 8%;
    left: 95%;
    font-size: 14px;
    color: #b2b2b2;
    display: none;
  }
  .hover-class {
    display: block;
    color:#00bf8a;
  }
  &:hover {
    i {
      display: block;
    }
  }
}
</style>
